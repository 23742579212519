import { MyCard, MyGridList } from '@myfront/tdesign-components'
import { Button, Icon, Input } from 'tdesign-vue-next'
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator'

// 宫格列表组件
@Component
export default class GridList extends Vue {
  state?: any

  @Prop({
    default: 3
  })
  maxCol?: number

  @Prop({
    default: 400
  })
  cardMinWidth?: number

  @Prop({
    default: 20
  })
  gap?: number

  @Prop({
    default: '50%'
  })
  heightRatio?: string

  @Prop({
    default: 'default'
  })
  theme?: string

  @Emit("clicked")
  handle(e: any) {
    return e
  }

  // 处理toolbar区渲染
  renderToolbar() {
    //修改 去掉class类 pb-3
    return <div class="w-full flex items-center justify-between">
      <div>
        <slot name="toolbar-left">{this.renderToolbarLeft()}</slot>
      </div>
      <div>
        <slot name="toolbar-right">
          {this.renderToolbarRight()}
        </slot>
      </div>
    </div>
  }

  // 处理分页
  async pageChange(info: { current: number, previous: number, pageSize: number, total: number }) {
    this.state.pagination = {
      ...this.state.pagination,
      current: info.current,
      pageSize: info.pageSize,
      total: info.total
    }
    this.loadData && await this.loadData()
  }

  // 处理筛选输入
  async inputChange(value: string) {
    this.state.queryParams.q = value
    this.loadData && await this.loadData(true)
  }

  // 处理工具区左侧渲染, 一般会被外部组件覆写掉
  renderToolbarLeft() {
    return <Button>创建</Button>
  }

  // 处理工具区右侧渲染
  renderToolbarRight() {
    return <Input value={this.state?.queryParams?.q} onChange={value => this.inputChange(value)} placeholder={this.state?.queryParams?.placeholder || '筛选'}>
      {{
        suffix: () => {
          return <Button variant='text' onClick={e => this.loadData && this.loadData()}>
            <Icon name="search" />
          </Button>
        }
      }}
    </Input>
  }

  @Emit('actionClicked')
  handleActionClick(e: any) {
    return e
  }

  @Emit('click')
  handleClick(e: any) {
    return e
  }

  render() {
    return <>
      <MyCard v-slots={{
        header: () => <slot name="toolbar">
          {this.renderToolbar()}
        </slot>
      }}>
        {this.state?.data?.length > 0 && <MyGridList
          schema={this.$data?.schema}
          onActionClick={this.handleActionClick}
          onClick={this.handleClick}
          maxCol={this.maxCol}
          cardMinWidth={this.cardMinWidth}
          gap={this.gap}
          heightRatio={this.heightRatio}
          theme={this.theme}
          actions={this.$data.actions || []}
          data={this.state.data || []}>
          {{ ...this.$data.slots }}
        </MyGridList>}
        {
          (this.state?.data?.length === 0 || this.state?.data?.length === null) && <div style='color: #666;font-size:12px;text-align:center;padding-top: 100px'>暂无数据</div>
        }
      </MyCard>

    </>
  }
}
