import { Component, Vue, Prop, Setup, Emit } from 'vue-facing-decorator'
import { MyList } from '@myfront/tdesign-components'
//筛选列表组件
@Component
export default class FilterList extends Vue {
  state?: any

  @Prop({
    default: ''
  })
  value?: string

  @Prop({
    default: ''
  })
  title?: string

  @Setup((props) => {
    return props.value != undefined ? [props.value] : []
  })
  innerValue?: any

  @Emit('selected')
  handle(param: any) {
    this.innerValue = param.value
    return param.value
  }

  render() {
    return <>
      <div class="text-black w-full h-full">
        <MyList
          data={this.state?.value || []}
          activable={true}
          actived={this.value}
          title={this.title}
          onClick={this.handle.bind(this)}
        ></MyList>
      </div >
    </>
  }
}
