import { Component, Vue, Prop } from 'vue-facing-decorator'
import { MyTable, MyCard } from '@myfront/tdesign-components'
import { Button, Input, Icon } from 'tdesign-vue-next'

// table型列表组件
@Component
export default class TableList extends Vue {
  /**
   * 行数据的唯一标识
   */
  @Prop({
    default: 'id'
  })
  rowKey?: string

  loadData() { }

  state?: any

  // 记录多行选择
  selected: any[] = []

  // 处理分页
  async pageChange(info: { current: number, previous: number, pageSize: number, total: number }) {
    this.state.pagination = {
      ...this.state.pagination,
      current: info.current,
      pageSize: info.pageSize,
      total: info.total
    }
    this.loadData && await this.loadData()
  }

  // 处理筛选输入
  async inputChange(value: string) {
    this.state.queryParams.q = value
    this.loadData && await this.loadData()
  }

  // 处理toolbar区渲染
  renderToolbar() {
    return <div class="w-full flex items-center justify-between">
      <div>
        <slot name="toolbar-left">{this.renderToolbarLeft()}</slot>
      </div>
      <div>
        <slot name="toolbar-right">
          {this.renderToolbarRight()}
        </slot>
      </div>
    </div>
  }

  // 处理工具区左侧渲染, 一般会被外部组件覆写掉
  renderToolbarLeft() {
    return <Button>创建</Button>
  }

  // 处理工具区右侧渲染
  renderToolbarRight() {
    return <Input value={this.state?.queryParams.q} onChange={value => this.inputChange(value)} placeholder={this.state?.queryParams.placeholder || '筛选'}>
      {{
        suffix: () => {
          return <Button variant='text' onClick={e => this.loadData && this.loadData()}>
            <Icon name="search" />
          </Button>
        }
      }}
    </Input>
  }

  // 处理多行选择
  onMultiSelectHandler(value: any[]) {
    this.selected = value
  }

  render() {
    return <>
      <MyCard v-slots={{
        header: () => <slot name="toolbar">
          {this.renderToolbar()}
        </slot>
      }}>

        <MyTable
          columns={this.$data.table}
          data={this.state?.data || []}
          loading={this.state?.loading}
          max-height="100%"
          table-layout="auto"
          row-key={this.rowKey}
          colSlot={this.$data.colSlot}
          pagination={this.state?.pagination}
          onPageChange={(info: { current: number, previous: number, pageSize: number, total: number }) => this.pageChange(info)}
          onMultiSelect={(value: any[]) => this.onMultiSelectHandler(value)}
        >
          {{ ...this.$data.slots }}
        </MyTable>
      </MyCard>
    </>
  }
}
