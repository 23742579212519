import { useNuxtApp } from '#imports'
import { Button, DialogPlugin, MessageInfoOptions, MessagePlugin } from 'tdesign-vue-next'
import { Component, Vanilla, Vue } from 'vue-facing-decorator'
import z from 'zod'
import { TRPCClientError } from '@trpc/client'
import type { client } from '../plugins/1.trpc'

/**
 * 模态框传入选项
 */
export type DialogOption = {
  width?: string | number
  header: string | (() => string | JSX.Element)
  body: () => string | JSX.Element
  className?: string
  style?: string
  confirmBtn?: string | null
  cancelBtn?: string | null
  onConfirm: Function
  onClose: Function
  footerAlign?: string
  footer?: boolean | JSX.Element
}

@Component
export default class Base extends Vue {
  private _client!: client
  private _resource!: any
  private _app!: any

  loadData() { }
  state?: any

  @Vanilla
  get app(): client {
    if (!this._app) {
      this._app = useNuxtApp()
    }

    return this._app
  }

  @Vanilla
  get client(): client {
    if (!this._client) {
      this._client = this.app.$client as client
    }

    return this._client
  }

  @Vanilla
  get resource() {
    if (!this._resource) {
      this._resource = this.app.$resource
    }

    return this._resource
  }

  // 服务端预渲染
  async serverPrefetch() {
    if (this.loadData) {
      try {
        await this.loadData()
      } catch (e: unknown) {
        console.error('unkown errr', e)
      }
    }
  }

  // 如果未经服务端预渲染，可能不存在state,需在客户端再请求一次
  async mounted() {
    // Notice 注意这里的判断，后面的主要针对列表页，请务必数据在在state.data上
    if ((!this.state || !this.state?.data || !this.state.value) && this.loadData) {
      await this.loadData()
    }
  }

  success(msg: string | MessageInfoOptions, duration?: number) {
    return MessagePlugin.success(msg, duration || 3000)
  }

  error(msg: string | MessageInfoOptions, duration?: number) {
    return MessagePlugin.error(msg, duration || 3000)
  }

  info(msg: string | MessageInfoOptions, duration?: number) {
    return MessagePlugin.info(msg, duration || 3000)
  }

  warning(msg: string | MessageInfoOptions, duration?: number) {
    return MessagePlugin.warning(msg, duration || 3000)
  }

  /**
   * 统一处理模态框
   * @param option DialogOption
   */
  dialog(option: DialogOption) {
    const { header, body, confirmBtn, cancelBtn, onConfirm, onClose, style = '', className = '', footerAlign = 'right', width = '480px', footer } = option
    const confirmDia = DialogPlugin({
      width,
      header,
      body,
      style,
      className,
      confirmBtn: confirmBtn || '确定',
      cancelBtn: cancelBtn || '取消',
      onConfirm: ({ e }) => {
        onConfirm({ e })
        // confirmDia.hide()
      },
      onClose: ({ e, trigger }) => {
        onClose({ e })
        // confirmDia.hide()
      },
      footer: footer === undefined ? (h) => h(
        'div',
        { style: `display:flex;justify-content: ${{ left: 'start', center: 'center', right: 'end' }[footerAlign]};` },
        footerAlign === 'right'
          ? [
            h(Button, { variant: 'outline', onClick: (e: MouseEvent) => onClose({ e }) }, cancelBtn || '取消'),
            h(Button, { onClick: (e: MouseEvent) => onConfirm({ e }) }, confirmBtn || '确定')
          ]
          : [
            h(Button, { onClick: (e: MouseEvent) => onConfirm({ e }) }, confirmBtn || '保存'),
            h(Button, { variant: 'outline', onClick: (e: MouseEvent) => onClose({ e }) }, cancelBtn || '取消')
          ]

      ) : footer
    })
    return confirmDia
  }

  /**
   * 统一处理error
   * @param e
   * @returns string
   */
  normalizeErr(error: unknown): string {
    let message
    let code
    if (error instanceof TRPCClientError) {
      // console.log('trpc client err', error.meta?.responseJSON)
      message = error.meta?.responseJSON?.message
      code = error.meta?.responseJSON?.code
    } else if (typeof error === 'string') {
      message = error
    } else if (error instanceof Error) {
      message = error.message
    }

    if (!message) {
      message = '获取失败'
    }

    // TODO 特殊处理, 跳回首页
    if (code && (code === 60004 || code === 60001 || code === 30001)) {
      navigateTo('/')
    }

    return message
  }

  /**
   * 获取orderBy排序zod类型
   * @returns ZodEnum
   */
  sortOrder() {
    return z.enum(['asc', 'desc'])
  }
}
