import { Component, Vue, Prop, Setup, Emit } from 'vue-facing-decorator'
import { MyTree } from '@myfront/tdesign-components'
import { CubeIcon } from '@heroicons/vue/24/outline'
import { FunctionalComponent } from 'vue'
import { TScroll } from 'tdesign-vue-next'
// 树形查看组件
@Component
export default class TreeList extends Vue {
  state?: any

  @Prop({
    default: { label: 'name', value: 'id', children: 'children' }
  })
  keys?: any

  @Prop({
    default: ''
  })
  value?: string
  // 懒加载和虚拟滚动
  @Prop()
  scroll?: TScroll

  @Prop({
    default: ['folder', 'folder', CubeIcon]
  })
  icons?: Array<string | FunctionalComponent>

  @Setup((props) => {
    return props.value != undefined ? [props.value] : []
  })
  innerValue?: any

  @Emit('selected')
  handle(param: { value: string, context: any }) {
    this.innerValue = param.value
    return param.context.node
  }

  render() {
    return <>
      <div class="text-black w-full h-full">
        <MyTree
          expandAll={true}
          searchShow={true}
          data={this.state?.value || []}
          keys={this.keys}
          onTreeActive={this.handle.bind(this)}
          actived={this.value}
          prefixIcons={this.icons}
          scroll={this.scroll}
        />
      </div>
    </>
  }
}
