import { Component, Vue, Emit } from 'vue-facing-decorator'
import { MyStackList } from '@myfront/tdesign-components'

// 栈列表组件
@Component
export default class StackList extends Vue {
  state?: any

  @Emit("clicked")
  handle(e: any) {
    return e
  }

  render() {
    return <>
      <div>
        <MyStackList
          classNames="custom-stack-list"
          onClicked={this.handle}
          schema={this.$data.schema}
          data={this.state.data}>
          {{ ...this.$data.slots }}
        </MyStackList>
      </div>
    </>
  }
}
