import { Component, Prop, Ref, Vue, Emit } from 'vue-facing-decorator'
import { MyForm } from '@myfront/tdesign-components'

// 表单编辑组件
@Component
export class Form extends Vue {
  @Prop({
    default: '保存'
  })
  confirmBtn?: string

  @Prop({
    default: '取消'
  })
  cancelBtn?: string

  @Prop({
    default: true
  })
  showBtn?: boolean

  @Prop({
    default: 'right'
  })
  labelAlign?: string

  @Ref
  myFormRef?: HTMLDivElement

  get $form() {
    // @ts-expect-error 忽略warning
    return this.$refs.myFormRef.$refs.form
  }

  state?: any

  // 派发reset到上层业务组件
  @Emit('reset')
  resetHandler(e: any) {
    // 重置一下表单数据
    this.$form.reset()
    return e
  }

  // 派发submit到上层业务组件
  @Emit('submit')
  submitHandler(e: { result: booleaan | object }) {
    // 重置一下表单数据
    this.$form.reset()
    // if (this.save) {
    //   this.save(e)
    // }
    return e
  }

  renderFormFoot() {
    return <><div></div></>
  }

  render() {
    return <>
      {/* form:{JSON.stringify(this.state)} */}
      {this.state && <MyForm confirmBtn={this.confirmBtn} cancelBtn={this.cancelBtn} labelAlign={this.labelAlign} ref="myFormRef" data={this.state} content={this.$data.form} onReset={this.resetHandler} onSubmit={this.save} showBtn={this.showBtn}>
        {{
          ...this.$data.slots,
          foot: () => {
            if (!this.showBtn && this.renderFormFoot) {
              return this.renderFormFoot()
            }
          }
        }}
      </MyForm>
      }
    </>
  }
}
