import { Component, Vue } from 'vue-facing-decorator'
import { Alert, Button } from 'tdesign-vue-next'
import CatalogEditor from '../../modules/catalog/components/catalog/CatalogEditor'
import { ClientOnly } from '#components'

// 树形编辑组件
@Component
export default class TreeForm extends Vue {
  state?: any
  key = 0

  generateRandomString(length: number) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    return result
  }

  addRootNode() {
    this.state.value.unshift({
      name: '未命名',
      id: this.generateRandomString(6),
      children: []
    })
    this.key++
  }

  async mounted() {
    if ((!this.state || !this.state?.data) && this.loadData) {
      await this.loadData()
      this.key++
    }
  }

  render() {
    return <>
      <div class="mb-5">
        <Alert
          message="双击节点，进入编辑模式，回车可确认"
        />
      </div>
      <div class="flex justify-between mb-5">
        <Button
          onClick={this.addRootNode}
        >
          添加根节点
        </Button>
        <Button
          onClick={this.save}
        >
          保存
        </Button>
      </div>
      <ClientOnly>
        <CatalogEditor
          key={this.key}
          data={this.state?.value}
          onUpdate:data={value => this.state.value = value}
        />
      </ClientOnly>
    </>
  }
}
