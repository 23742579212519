import { Component, Prop, Emit, Vue } from 'vue-facing-decorator'
import { MyTreeSelect } from '@myfront/tdesign-components'
import XEUtils from 'xe-utils'

// 树形选择组件
@Component
export default class TreeSelect extends Vue {

  @Prop({
    default: false
  })
  multiple?: boolean

  /**
   * options数据的字段名自定义等
   */
  @Prop({
    default: { keys: { value: 'id', label: 'name', children: 'children' }, expandOnClickNode: false, expandAll: true, exapandLevel: 3, activable: true }
  })
  treeProps?: object
  /**
   * 被选择的选项
   */
  @Prop({
    default: ''
  })
  selected?: string | string[] | object[]

  /**
   * 侦听选择变化， 触发update:selected事件， 可由onUpdate:selected属性侦听
   * @param value
   * @returns
   */
  @Emit('update:selected')
  changeHandler(value: string) {
    return value
  }

  @Prop()
  onChange?: Function

  state?: any

  // 获取节点
  getTreeNode(value: number | string) {
    const node = XEUtils.findTree(this.state.value || [], item => item[this.treeProps?.keys?.value || 'id'] === value)
    let data: any = null
    if (node) {
      data = {
        data: node.item,
        parents: node.nodes.slice(0, -1),
        level: node.nodes.length - 1
      }
      return data
    }
  }

  render() {
    return <>
      {/* selected--{this.multiple ? 'true' : 'false'} */}
      <MyTreeSelect multiple={this.multiple} value={this.selected} onUpdate:value={value => this.changeHandler(value)} options={this.state?.value} tree-props={this.treeProps} onChange={this.onChange} loading={this.state.loading}>
      </MyTreeSelect>
    </>
  }
}
