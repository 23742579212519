import { Component, Vue, Prop, Ref, Emit } from 'vue-facing-decorator'
import { MyTableTree, MyCard } from '@myfront/tdesign-components'
import { Button, Input, Icon, TScroll } from 'tdesign-vue-next'
import { FunctionalComponent } from 'vue'

// table树型列表组件
@Component
export default class TableTreeList extends Vue {

  @Prop({
    default: false
  })
  selectable?: boolean

  @Prop({
    default: ['city-10', 'folder', 'control-platform']
  })
  icons?: Array<string | FunctionalComponent>

  // 是否默认全部展开
  @Prop({
    default: true
  })
  expandAll?: boolean

  // rowKey配置
  @Prop({
    default: 'value',
  })
  rowKey?: string

  // 折叠按钮在哪一列展示
  @Prop({
    default: 0
  })
  treeNodeColumnIndex?: number

  // 是否允许drag排序
  @Prop({
    default: false
  })
  dragable?: boolean

  // 是否显示序号
  @Prop({
    default: false
  })
  sequence?: boolean

  // 表格本地筛选的键值
  @Prop
  filterValue: { [colKey: string]: any }

  // 虚拟滚动配置
  @Prop
  scroll?: TScroll

  @Ref
  myTreeTableRef?: HTMLDivElement & { $refs: any }

  loadData() { }

  // 获取到底层组件实例，方便使用其方法函数
  get $table() {
    //@ts-ignore
    return this.$refs?.myTreeTableRef?.$refs.table
  }

  state?: any

  // 记录多行选择
  selected: any[] = []

  // 处理分页
  async pageChange(info: { current: number, previous: number, pageSize: number, total: number }) {
    this.state.pagination = {
      ...this.state.pagination,
      current: info.current,
      pageSize: info.pageSize,
      total: info.total
    }
    this.loadData && await this.loadData()
  }

  // 处理筛选输入
  async inputChange(value: string) {
    this.state.queryParams.q = value
    this.loadData && await this.loadData()
  }

  // 处理toolbar区渲染
  renderToolbar() {
    return <div class="w-full flex items-center justify-between">
      <div>
        <slot name="toolbar-left">{this.renderToolbarLeft()}</slot>
      </div>
      <div>
        <slot name="toolbar-right">
          {this.renderToolbarRight()}
        </slot>
      </div>
    </div>
  }

  // 处理工具区左侧渲染, 一般会被外部组件覆写掉
  renderToolbarLeft() {
    return <Button>创建</Button>
  }

  // 处理工具区右侧渲染
  renderToolbarRight() {
    return <Input value={this.state?.queryParams.q} onChange={value => this.inputChange(value)} placeholder={this.state?.queryParams.placeholder || '筛选'}>
      {{
        suffix: () => {
          return <Button variant='text' onClick={e => this.loadData && this.loadData()}>
            <Icon name="search" />
          </Button>
        }
      }}
    </Input>
  }

  // 处理多行选择
  onMultiSelectHandler(value: any[]) {
    this.selected = value
  }

  @Emit('dragSorted')
  onDragSorted(e: any) {
    return e
  }

  render() {
    return <>
      <MyCard>
        {{
          header: () => {
            return <>
              <slot name="toolbar">
                {this.renderToolbar()}
              </slot>
            </>
          },
          default: () => {
            return <>
              <MyTableTree
                rowKey={this.rowKey}
                ref="myTreeTableRef"
                expandAll={this.expandAll}
                tree={{ treeNodeColumnIndex: this.treeNodeColumnIndex }}
                sequence={this.sequence}
                columns={this.$data.table}
                data={this.state?.data || []}
                selectable={this.selectable}
                dragable={this.dragable}
                onDragSorted={this.onDragSorted}
                filterValue={this.filterValue}
                loading={this.state.loading}
                scroll={this.scroll}
              >
                {{
                  label: (param: { node: any, row: any, childrenCount: number }) => {
                    return <>
                      <div class="inline-flex items-center justify-center">
                        {
                          (!param.row.children || param.row.children.length == 0) &&
                          <div style={{ marginRight: 'var(--td-comp-margin-s)' }}><svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" class="t-icon t-icon-chevron-down"></svg></div>
                        }
                        <Icon style={{ color: "#999" }} name={this.icons[param.node.level] || 'control-platform'} />
                        <span style={{ fontSize: '12px', marginLeft: '3px' }}>{param.row.label}</span>
                      </div>
                    </>
                  },
                  ...this.$data.slots
                }}
              </MyTableTree>
            </>
          }
        }}


      </MyCard>
    </>
  }
}
