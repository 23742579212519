import { Component, Prop, Emit, Vue } from 'vue-facing-decorator'
import { MyTransfer } from '@myfront/tdesign-components'

// 穿梭框选择类组件
@Component
export default class TransferSelect extends Vue {

  /**
   * 自动根据配置生成keys的属性配置
   */
  get keys() {
    const _keys = {
      value: 'value',
      label: 'label',
      children: 'children'
    }
    if (this.$data.select) {
      Object.keys(this.$data.select).map((key: string) => {
        _keys[key] = this.$data.select[key].fieldName
      })
    }

    return _keys
  }

  /**
   * 默认是否支持树型数据，默认不支持
   */
  @Prop({
    default: false
  })
  treeConfig?: boolean

  /**
   * 被选择的选项
   */
  @Prop({
    default: ''
  })
  selected?: string | string[] | object[]

  /**
   * 侦听选择变化， 触发update:selected事件， 可由onUpdate:selected属性侦听
   * @param value
   * @returns
   */
  @Emit('update:selected')
  changeHandler(value: string) {
    return value
  }

  state?: any

  render() {
    return <>
      {/* selected--{JSON.stringify(this.selected)}--{JSON.stringify(this.state.value)} */}
      <MyTransfer style={{minHeight: '400px'}} keys={this.keys} value={this.selected} searchable={true} onUpdate:value={value => this.changeHandler(value)} data={this.state?.value} treeConfig={this.treeConfig}>
      </MyTransfer>
    </>
  }
}
