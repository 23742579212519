import { Component } from 'vue-facing-decorator'
import { MyContent } from '@myfront/tdesign-components'
import Base from './base'

// 内容详情类组件
@Component
export class Detail extends Base {
  render() {
    return <>
      {/* {JSON.stringify(this.$data.detail)} */}
      {this.state && <MyContent detailSlots={this.$data.detailSlots} columns={this.$data.detail} data={this.state}>
      </MyContent>}
    </>
  }
}
